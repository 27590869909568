import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Login",
        component: function () {
            return import("../views/login/Login.vue");
        },
    },
    {
        path: "/register",
        name: "Register",
        component: function () {
            return import("../views/login/Register.vue");
        },
    },
    {
        path: "/resetpassword",
        name: "ResetPassword",
        component: function () {
            return import("../views/login/ResetPassword.vue");
        },
    },
    {
        path: "/about",
        name: "About",
        component: function () {
            return import(/* webpackChunkName: "about" */ "../views/About.vue");
        },
    },
    {
        path: "/home/client",
        name: "HomeClient",
        component: function () {
            return import("../views/app/client/Home.vue");
        },
    },
    {
        path: "/home/clientgroup",
        name: "HomeClientGroup",
        component: function () {
            return import("../views/app/clientgroup/Home.vue");
        },
    },
    {
        path: "/home/admin",
        name: "HomeAdmin",
        component: function () {
            return import("../views/app/admin/Home.vue");
        },
    },
    {
        path: "/groups/admin",
        name: "GroupsAdmin",
        component: function () {
            return import("../views/app/admin/Groups.vue");
        },
    },
    {
        path: "/cloud/client",
        name: "CloudClient",
        component: function () {
            return import("../views/app/client/Cloud.vue");
        },
    },
    {
        path: "/creator/client",
        name: "CreatorClient",
        component: function () {
            return import("../views/app/client/Creator.vue");
        },
    },
    {
        path: "/client/admin",
        name: "ManageClient",
        component: function () {
            return import("../views/app/admin/Client.vue");
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
