import userService from "../../services/user";
let user = {};

export default {
    namespaced: true,
    state: user,
    getter: {},
    actions: {
        async getUserWRAP({ commit }, { userId }) {
            try {
                const user = await userService.getUserWRAP(userId);
                return user;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },
        async getUser({ commit }, { userName }) {
            try {
                const user = await userService.getUser(userName);
                return user;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },
        async getAfterLogin({ commit }, { userName }) {
            try {
                const user = await userService.getAfterLogin(userName);
                commit("loginSuccess", user);
                return user;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },
        async register(
            { commit },
            { userName, password, phonenumber, email, address }
        ) {
            try {
                const user = await userService.register(
                    userName,
                    password,
                    phonenumber,
                    email,
                    address
                );
                return user;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },

        async login({ commit }, { userName, password }) {
            try {
                const response = await userService.login(userName, password);
                return response;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },
    },

    mutations: {
        loginSuccess(state, user) {
            state.user = user;
        },
    },
};
