import { HTTP, WRAP } from "./http-common";

export default {
    getGroupList,
    getDeviceGroup,
    createGroup,
    deleteGroup,
    moveDevice,
    editGroupName,
};

async function getGroupList(userName) {
    try {
        let bodyFormData = new FormData();
        bodyFormData.append("userName", userName);
        const response = (
            await HTTP({
                method: "POST",
                url: `User/groupList`,
                data: bodyFormData,
                withCredentials: true,
            })
        ).data;

        return response;
    } catch (error) {
        throw error.response;
    }
}

async function getDeviceGroup(groupId) {
    try {
        let response = (
            await WRAP({ method: "GET", url: `/wiikk/group/${groupId}/device` })
        ).data;

        return response;
    } catch (error) {
        throw error.response;
    }
}

async function createGroup(userName, groupName) {
    try {
        let bodyFormData = new FormData();
        bodyFormData.append("userName", userName);
        bodyFormData.append("groupName", groupName);
        let response = (
            await HTTP({
                method: "POST",
                url: `/User/addGroup`,
                data: bodyFormData,
                withCredentials: true,
            })
        ).data;

        return response;
    } catch (error) {
        throw error.response;
    }
}

async function deleteGroup(userName, groupId, groupName) {
    try {
        let bodyFormData = new FormData();
        bodyFormData.append("userName", userName);
        bodyFormData.append("groupId", groupId);
        bodyFormData.append("groupName", groupName);
        let response = (
            await HTTP({
                method: "POST",
                url: `/User/delGroup`,
                data: bodyFormData,
                withCredentials: true,
            })
        ).data;

        return response;
    } catch (error) {
        throw error.response;
    }
}

async function moveDevice(userName, deviceId, groupId) {
    try {
        let bodyFormData = new FormData();
        bodyFormData.append("userName", userName);
        bodyFormData.append("groupId", groupId);
        bodyFormData.append("deviceId", deviceId);
        let response = (
            await HTTP({
                method: "POST",
                url: `/User/updateDeviceGroup`,
                data: bodyFormData,
                withCredentials: true,
            })
        ).data;

        return response;
    } catch (error) {
        throw error.response;
    }
}
async function editGroupName(userName, groupId, groupName) {
    try {
        let bodyFormData = new FormData();
        bodyFormData.append("userName", userName);
        bodyFormData.append("groupId", groupId);
        bodyFormData.append("groupName", groupName);
        let response = (
            await HTTP({
                method: "POST",
                url: `/User/updateGroupName`,
                data: bodyFormData,
                withCredentials: true,
            })
        ).data;

        return response;
    } catch (error) {
        throw error.response;
    }
}
