import { HTTP, WRAP } from "./http-common";

export default {
    createMediaWRAP,
    getMedias,
    createMedia,
    deleteMedia,
    sendMedia,
    getMediaWRAP,
    formatDevice,
    deleteDeviceMedia,
    upgradeDevice,
};

async function createMedia(file, groupId) {
    try {
        let bodyFormData = new FormData();
        bodyFormData.append("file", file);
        bodyFormData.append("groupId", groupId);
        bodyFormData.append("isVersion", 3);

        let response = await HTTP({
            method: "POST",
            url: `device/uploadUi`,
            data: bodyFormData,
            withCredentials: true,
        });
        return response;
    } catch (error) {
        throw error.response;
    }
}
async function getMedias(groupId) {
    try {
        let bodyFormData = new FormData();
        bodyFormData.append("groupId", groupId);
        bodyFormData.append(
            "queryParams",
            '[{name="params",value="undefined"}]'
        );
        bodyFormData.append(
            "aoData",
            '[{"name":"sEcho","value":1},{"name":"iColumns","value":5},{"name":"sColumns","value":",,,,"},{"name":"iDisplayStart","value":0},{"name":"iDisplayLength","value":500},{"name":"mDataProp_0","value":"number"},{"name":"bSortable_0","value":true},{"name":"mDataProp_1","value":"imgsUrl"},{"name":"bSortable_1","value":true},{"name":"mDataProp_2","value":"resourcesName"},{"name":"bSortable_2","value":true},{"name":"mDataProp_3","value":"uplodDate"},{"name":"bSortable_3","value":true},{"name":"mDataProp_4","value":"number"},{"name":"bSortable_4","value":true},{"name":"iSortCol_0","value":0},{"name":"sSortDir_0","value":"asc"},{"name":"iSortingCols","value":1}]'
        );
        bodyFormData.append("isVersion", 3);

        let response = await HTTP({
            method: "POST",
            url: `device/UiList`,
            data: bodyFormData,
            withCredentials: true,
        });
        return response;
    } catch (error) {
        throw error.response;
    }
}
async function createMediaWRAP(resourceName, displayImageId, deviceId) {
    try {
        const response = (
            await WRAP({
                method: "POST",
                url: `/media`,
                data: {
                    resourceName: resourceName,
                    displayImageId: displayImageId,
                    deviceId: deviceId,
                },
            })
        ).data;

        return response.data;
    } catch (error) {
        throw error.response;
    }
}
async function getMediaWRAP(deviceId) {
    try {
        let response = (
            await WRAP({ method: "GET", url: `/media/${deviceId}` })
        ).data;
        return response.data;
    } catch (error) {
        throw error.response;
    }
}

async function deleteMedia(uiCode, advertisersCode) {
    try {
        let bodyFormData = new FormData();
        bodyFormData.append("uiCode", uiCode);
        bodyFormData.append("advertisersCode", advertisersCode);
        let response = await HTTP({
            method: "POST",
            url: `device/DeleteUi`,
            data: bodyFormData,
            withCredentials: true,
        });
        return response;
    } catch (error) {
        throw error.response;
    }
}

async function sendMedia(userName, deviceId, displayImageId) {
    try {
        let bodyFormData = new FormData();
        bodyFormData.append("userName", userName);
        bodyFormData.append("deviceId", deviceId);
        bodyFormData.append("displayImageId", displayImageId);
        let response = await HTTP({
            method: "POST",
            url: `device/upgradeDeviceUi`,
            data: bodyFormData,
            withCredentials: true,
        });
        return response;
    } catch (error) {
        throw error.response;
    }
}

async function formatDevice(userName, deviceId, needFormatSd) {
    try {
        let bodyFormData = new FormData();
        bodyFormData.append("userName", userName);
        bodyFormData.append("deviceId", deviceId);
        bodyFormData.append("needFormatSd", needFormatSd);
        let response = await HTTP({
            method: "POST",
            url: `device/needFormatSd`,
            data: bodyFormData,
            withCredentials: true,
        });

        await WRAP({ method: "DELETE", url: `media/${deviceId}/format` });
        return response;
    } catch (error) {
        throw error.response;
    }
}

async function deleteDeviceMedia(userName, deviceId, fileDelects, mediaId) {
    try {
        let bodyFormData = new FormData();
        bodyFormData.append("userName", userName);
        bodyFormData.append("deviceId", deviceId);
        bodyFormData.append("fileDelects", fileDelects);
        let response = await HTTP({
            method: "POST",
            url: `/User/fileDelects`,
            data: bodyFormData,
            withCredentials: true,
        });

        await WRAP({
            method: "DELETE",
            url: `media/${deviceId}/delete/${mediaId}`,
        });
        return response;
    } catch (error) {
        throw error.response;
    }
}

async function upgradeDevice(userName, deviceId) {
    try {
        let bodyFormData = new FormData();
        bodyFormData.append("userName", userName);
        bodyFormData.append("deviceId", deviceId);
        let response = await HTTP({
            method: "POST",
            url: `/User/upgradeDevice`,
            data: bodyFormData,
            withCredentials: true,
        });
        return response;
    } catch (error) {
        throw error.response;
    }
}
