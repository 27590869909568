import { HTTP, WRAP } from "./http-common";

export default {
    getUser,
    getUserWRAP,
    register,
    login,
    getAfterLogin,
};

async function getUserWRAP(userId) {
    try {
        const response = (await WRAP({ method: "GET", url: `/user/${userId}` }))
            .data;
        return response.data;
    } catch (error) {
        throw error.response;
    }
}

async function register(userName, password, phonenumber, email, address) {
    try {
        let bodyFormData = new FormData();
        bodyFormData.append("userName", userName);
        bodyFormData.append("password", password);
        bodyFormData.append("phonenumber", phonenumber);
        bodyFormData.append("email", email);
        bodyFormData.append("address", address);
        bodyFormData.append("backEmail", "");
        bodyFormData.append("backEmailCheckCode", "");
        bodyFormData.append("language", "1");
        const response = (
            await HTTP({
                method: "POST",
                url: `/User/reg_advertisers`,
                data: bodyFormData,
            })
        ).data;
        let user = (
            await WRAP({
                method: "GET",
                url: `/wiikk/user/${userName}`,
            })
        ).data;
        let userId = user.data.idAdvertiser;

        await WRAP({
            method: "POST",
            url: `/user/`,
            data: { username: userName, userId: userId, credentials: 0 },
        });
        return response.data;
    } catch (error) {
        throw error.response;
    }
}

async function getUser(userName) {
    try {
        let user = (
            await WRAP({
                method: "GET",
                url: `/wiikk/user/${userName}`,
            })
        ).data;

        return user;
    } catch (error) {
        throw error.response;
    }
}

async function login(userName, password) {
    try {
        let bodyFormData = new FormData();
        bodyFormData.append("userName", userName);
        bodyFormData.append("password", password);
        let response = await HTTP({
            method: "POST",
            url: "/User/loginR?date=" + new Date(),
            data: bodyFormData,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error.response;
    }
}

async function getAfterLogin(userName) {
    let userId = (
        await WRAP({
            method: "GET",
            url: `/wiikk/user/${userName}`,
        })
    ).data.data.idAdvertiser;
    let user = await WRAP({
        method: "GET",
        url: `/user/${userId}`,
    });
    return user.data.data;
}
