import mediaService from "../../services/media";

export default {
    namespaced: true,
    state: {},
    getter: {},
    actions: {
        async createMediaWRAP(
            { commit },
            { resourceName, displayImageId, deviceId }
        ) {
            try {
                const medias = await mediaService.createMediaWRAP(
                    resourceName,
                    displayImageId,
                    deviceId
                );
                return medias;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },
        async getMedias({ commit }, { groupId }) {
            try {
                const medias = await mediaService.getMedias(groupId);
                return medias;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },
        async createMedia({ commit }, { file, groupId }) {
            try {
                const response = await mediaService.createMedia(file, groupId);
                return response;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },
        async deleteMedia({ commit }, { uiCode, advertisersCode }) {
            try {
                const response = await mediaService.deleteMedia(
                    uiCode,
                    advertisersCode
                );
                return response;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },
        async sendMedia({ commit }, { userName, deviceId, displayImageId }) {
            try {
                const response = await mediaService.sendMedia(
                    userName,
                    deviceId,
                    displayImageId
                );
                return response;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },
        async getMediaWRAP({ commit }, { deviceId }) {
            try {
                const response = await mediaService.getMediaWRAP(deviceId);
                return response;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },
        async formatDevice({ commit }, { userName, deviceId, needFormatSd }) {
            try {
                const response = await mediaService.formatDevice(
                    userName,
                    deviceId,
                    needFormatSd
                );
                return response;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },

        async deleteDeviceMedia(
            { commit },
            { userName, deviceId, fileDelects, mediaId }
        ) {
            try {
                const response = await mediaService.deleteDeviceMedia(
                    userName,
                    deviceId,
                    fileDelects,
                    mediaId
                );
                return response;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },
        async upgradeDevice({ commit }, { userName, deviceId }) {
            try {
                const response = await mediaService.upgradeDevice(
                    userName,
                    deviceId
                );
                return response;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },
    },
};
