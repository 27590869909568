import axios from "axios";
import config from "../config";
import store from "../store";
import router from "../router";

const HTTP = axios.create({
    baseURL: `${config.api}`,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

const WRAP = axios.create({
    baseURL: `${config.wrap}`,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

HTTP.interceptors.request.use(function (config) {
    let access_token = localStorage.getItem("access_token");
    if (access_token) {
        config.headers["Authorization"] = "Bearer " + access_token;
    }
    return config;
});

HTTP.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            if (error.response.data.meta) {
                if (error.response.data.meta.error === "expired_token") {
                    return axios({
                        method: "post",
                        url: `${config.api}/token/refresh`,
                        data: {
                            token: localStorage.getItem("refresh_token"),
                        },
                    })
                        .then((r) => {
                            const token =
                                r.data.data && r.data.data.access_token;
                            if (token) {
                                const config = error.config;
                                localStorage.setItem("access_token", token);
                                config.headers[
                                    "Authorization"
                                ] = `Bearer ${token}`;
                                return new Promise((resolve, reject) => {
                                    axios(config)
                                        .then((response) => {
                                            resolve(response);
                                        })
                                        .catch((error) => {
                                            reject(error);
                                        });
                                });
                            } else {
                                return new Promise((resolve, reject) => {
                                    reject("Internal error");
                                });
                            }
                        })
                        .catch(() => {
                            store.dispatch("user/logout");
                            router.push({ name: "Login" });
                        });
                } else if (error.response.data.meta.error === "invalid_token") {
                    store.dispatch("user/logout");
                    router.push({ name: "Login" });
                }
            }
        }
        throw error;
    }
);

export { HTTP, WRAP };
