import { HTTP, WRAP } from "./http-common";

export default {
    getAllGroupList,
    getAllDevice,
    updateDeviceName,
    addDeviceToGroup,
    poweronoffdevice,
    getAllUser,
};

async function getAllGroupList() {
    try {
        const response = (
            await WRAP({ method: "GET", url: `/wiikk/admin/groups` })
        ).data;
        return response.data;
    } catch (error) {
        throw error.response;
    }
}

async function getAllDevice() {
    try {
        const response = (
            await WRAP({ method: "GET", url: `/wiikk/admin/devices` })
        ).data;
        return response.data;
    } catch (error) {
        throw error.response;
    }
}

async function updateDeviceName(userName, deviceId, deviceName) {
    try {
        let bodyFormData = new URLSearchParams();
        bodyFormData.append("userName", userName);
        bodyFormData.append("deviceId", deviceId);
        bodyFormData.append("deviceName", deviceName);
        let response = await HTTP({
            method: "POST",
            url: `User/updateDeviceName`,
            data: bodyFormData,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error.response;
    }
}

async function addDeviceToGroup(userName, deviceId, groupId) {
    try {
        let bodyFormData = new URLSearchParams();
        bodyFormData.append("userName", userName);
        bodyFormData.append("deviceId", deviceId);
        bodyFormData.append("groupId", groupId);
        let response = await HTTP({
            method: "POST",
            url: "/User/updateDeviceGroup",
            data: bodyFormData,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error.response;
    }
}

async function poweronoffdevice(
    userName,
    deviceId,
    devicePowerOn,
    devicePowerOff
) {
    try {
        let bodyFormData = new FormData();
        bodyFormData.append("userName", userName);
        bodyFormData.append("deviceId", deviceId);
        bodyFormData.append("devicePowerOff", devicePowerOff);
        bodyFormData.append("devicePowerOn", devicePowerOn);

        // if(devicePowerOff == true){
        //     bodyFormData.append("devicePowerOff", devicePowerOff);
        // }
        let response = (
            await HTTP({
                method: "POST",
                url: "/User/devicePower",
                data: bodyFormData,
                withCredentials: true,
            })
        ).data;
        return response;
    } catch (error) {
        throw error.response;
    }
}

async function getAllUser() {
    try {
        let response = (
            await WRAP({
                method: "GET",
                url: "/wiikk/users",
            })
        ).data;
        return response.data;
    } catch (error) {
        throw error.response;
    }
}
