let error = "";
let modal = false;
let initialState = { error, modal };

export default {
    namespaced: true,
    state: initialState,
    getters: {},
    actions: {},
    mutations: {
        changeError(state, error) {
            state.error = error;
        },
        openModal(state) {
            state.modal = true;
        },
        closeModal(state) {
            state.modal = false;
        },
    },
};
