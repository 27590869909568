import adminService from "../../services/admin";

export default {
    namespaced: true,
    state: {},
    getter: {},
    actions: {
        async getAllGroupList({ commit }) {
            try {
                const groups = await adminService.getAllGroupList();
                return groups;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },

        async getAllDevice({ commit }) {
            try {
                const devices = await adminService.getAllDevice();
                return devices;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },

        async updateDeviceName({ commit }, { userName, deviceId, deviceName }) {
            try {
                const response = await adminService.updateDeviceName(
                    userName,
                    deviceId,
                    deviceName
                );
                return response;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },

        async addDeviceToGroup({ commit }, { userName, deviceId, groupId }) {
            try {
                const response = await adminService.addDeviceToGroup(
                    userName,
                    deviceId,
                    groupId
                );
                return response;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },

        async poweronoffdevice(
            { commit },
            { userName, deviceId, devicePowerOn, devicePowerOff }
        ) {
            try {
                const response = await adminService.poweronoffdevice(
                    userName,
                    deviceId,
                    devicePowerOn,
                    devicePowerOff
                );
                return response;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },

        async getAllUser({ commit }) {
            try {
                const response = await adminService.getAllUser();
                return response;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },
    },
};
