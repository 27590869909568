<template>
    <div id="app">
        <div id="nav"></div>
        <router-view />
        <ErrorModal />
    </div>
</template>
<script>
import ErrorModal from "@/components/error/ModalError";
export default {
    components: {
        ErrorModal,
    },
};
</script>
<style>
#app {
    font-family: "Myriad Pro", Myriad, "Liberation Sans", "Nimbus Sans L",
        "Helvetica Neue", Helvetica, Arial, sans-serif, regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
</style>
