import { HTTP, WRAP } from "./http-common";

export default {
    getRSA,
    encryptPassword,
};
async function getRSA() {
    try {
        let date = Date();
        date.replace("+", " ");
        const response = (
            await HTTP({
                method: "GET",
                url: "/device/DeviceLoginR?date=" + new Date(),
                withCredentials: true,
                sameSite: "none",
                secure: "true",
            })
        ).data;
        return response;
    } catch (error) {
        throw error.response;
    }
}

async function encryptPassword(password, publicKey) {
    try {
        const response = (
            await WRAP({
                method: "POST",
                url: "/user/encryptpassword",
                data: { password: password, publicKey: publicKey },
            })
        ).data;
        return response.data;
    } catch (error) {
        throw error.response;
    }
}
