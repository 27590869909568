import rsaService from "../../services/rsa";

export default {
    namespaced: true,
    state: {},
    getter: {},
    actions: {
        async getRSA({ commit }) {
            try {
                const rsa = await rsaService.getRSA();
                return rsa;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },

        async encryptPassword({ commit }, { password, publicKey }) {
            try {
                const hashPassword = await rsaService.encryptPassword(
                    password,
                    publicKey
                );
                return hashPassword;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },
    },
};
