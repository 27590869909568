import groupService from "../../services/group";

export default {
    namespaced: true,
    state: {},
    getter: {},
    actions: {
        async getGroupList({ commit }, { userName }) {
            try {
                const groups = await groupService.getGroupList(userName);
                return groups;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },

        async getDeviceGroup({ commit }, { groupId }) {
            try {
                const devices = await groupService.getDeviceGroup(groupId);
                return devices;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },

        async createGroup({ commit }, { userName, groupName }) {
            try {
                const groupe = await groupService.createGroup(
                    userName,
                    groupName
                );
                return groupe;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },
        async moveDevice({ commit }, { userName, deviceId, groupId }) {
            try {
                await groupService.moveDevice(userName, deviceId, groupId);
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },
        async deleteGroup({ commit }, { userName, groupId, groupName }) {
            try {
                const result = await groupService.deleteGroup(
                    userName,
                    groupId,
                    groupName
                );
                return result;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },
        async editGroupName({ commit }, { userName, groupId, groupName }) {
            try {
                const result = await groupService.editGroupName(
                    userName,
                    groupId,
                    groupName
                );
                return result;
            } catch (error) {
                commit("error/changeError", error, { root: true });
                commit("error/openModal", null, { root: true });
            }
        },
    },
};
