import Vue from "vue";
import Vuex from "vuex";
import userModule from "./modules/user";
import errorModule from "./modules/error";
import rsaModule from "./modules/rsa";
import groupModule from "./modules/group";
import admin from "./modules/admin";
import media from "./modules/media";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        user: userModule,
        error: errorModule,
        rsa: rsaModule,
        group: groupModule,
        admin: admin,
        media: media,
    },
    plugins: [
        createPersistedState({
            paths: ["user", "error", "rsa", "group", "admin", "media"],
            storage: sessionStorage,
        }),
    ],
});
