<template>
    <b-modal v-model="modal" hide-footer hide-header>
        <div class="row">
            <div class="col-lg-12 text-center">
                <h3 class="custom-text-danger">Erreur</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-center">
                <p>{{ message }}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-center">
                <b-button v-on:click="closeModal" class="custom-btn-secondary"
                    >Fermer</b-button
                >
            </div>
        </div>
    </b-modal>
</template>
<script>
export default {
    computed: {
        message: function () {
            let error = this.$store.state.error.error.data.meta.error;
            if (error == "invalid_param") {
                return "Un paramètre de la requête est invalide";
            } else if (error == "reset_password_failed") {
                return "Réinitialisation du mot de passe échoué. L'utilisateur non trouvé ou token invalide";
            } else if (error == "ressource_exists") {
                return "La ressource est déjà présente";
            } else if (error == "validation_failed") {
                return "La vérification des paramètres à échoué. Un champ est invalide, il peut être vide, null ou invalide";
            } else if (error == "credentials_too_low") {
                return "Vous n'avez pas accès à cette fonction";
            } else if (error == "expired_token") {
                return "Votre Token à espiré. Veuillez vous reconnecter";
            } else if (error == "invalid_token") {
                return "Token invalide";
            } else if (error == "action_forbidden") {
                return "Action interdite";
            } else if (error == "login_failed") {
                return "Email ou mot de passe invalide";
            } else if (error == "missing_header") {
                return "Non autorisé";
            } else if (error == "ressource_not_found") {
                return "Ressource non trouvé";
            } else if (error == "create_failed") {
                return "La création à échoué";
            } else if (error == "delete_failed") {
                return "La supression à échoué";
            } else if (error == "select_failed") {
                return "La selection à échoué";
            } else if (error == "update_failed") {
                return "La modification à échoué";
            } else if (error == "not_implemented") {
                return "La ressource n'est pas implémenté";
            } else if (error == "internal_error") {
                return "Une erreur interne inattendue s'est produite";
            }
            return error;
        },
        modal: function () {
            return this.$store.state.error.modal;
        },
    },
    methods: {
        closeModal: function () {
            this.$store.commit("error/closeModal", null, { root: true });
        },
    },
};
</script>
